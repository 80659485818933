// src/components/SendMail.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SendMail.css'; // 引入样式文件

const SendMail = () => {
  const navigate = useNavigate();
  const [returnAddress, setReturnAddress] = useState({
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  });

  const [recipientAddress, setRecipientAddress] = useState({
    name: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  });

  const states = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];

  const handleInputChange = (e, addressType) => {
    const { name, value } = e.target;
    if (addressType === 'return') {
      setReturnAddress((prevState) => ({ ...prevState, [name]: value }));
    } else {
      setRecipientAddress((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const standardizeAddress = (address, setAddress) => {
    const standardizedAddress = {};
    for (const key in address) {
      standardizedAddress[key] = address[key].toUpperCase();
    }
    setAddress(standardizedAddress);
  };

  const handleSubmit = () => {
    const payload = {
      returnAddress,
      recipientAddress,
    };

    if (!validateAddress(returnAddress) || !validateAddress(recipientAddress)) {
      alert('Please fill in all required fields.');
      return;
    }

    fetch('/api/sendmail', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    }).then((response) => {
      if (response.ok) {
        alert('Tracking number generated successfully!');
      } else {
        alert('Failed to generate tracking number.');
      }
    });
  };

  const validateAddress = (address) => {
    return address.name && address.address1 && address.city && address.state && address.zip;
  };

  return (
    <div className="sendmail-page">
      <header className="sendmail-header">
        <div className="sendmail-header-content">
          <h1>UUTracking - A Faster way to track Regular USPS First-class Mail</h1>
          <h2>Created by Yifan, powered by USPS Informed Visibility</h2>
        </div>
        <div className="sendmail-header-buttons">
          <button onClick={() => navigate('/login')}>Login</button>
          <button onClick={() => navigate('/register')}>Register</button>
        </div>
      </header>
      <div className="sendmail-container">
        <h2>Send a Letter/Postcard</h2>
        <div className="address-section">
          <h3>Return Address</h3>
          <input
            type="text"
            name="name"
            placeholder="Full Name/Company Name"
            value={returnAddress.name}
            onChange={(e) => handleInputChange(e, 'return')}
          />
          <input
            type="text"
            name="address1"
            placeholder="Address Line 1"
            value={returnAddress.address1}
            onChange={(e) => handleInputChange(e, 'return')}
          />
          <input
            type="text"
            name="address2"
            placeholder="Address Line 2"
            value={returnAddress.address2}
            onChange={(e) => handleInputChange(e, 'return')}
          />
          <input
            type="text"
            name="city"
            placeholder="City"
            value={returnAddress.city}
            onChange={(e) => handleInputChange(e, 'return')}
          />
          <div className="state-zip-standardize">
            <select
              name="state"
              value={returnAddress.state}
              onChange={(e) => handleInputChange(e, 'return')}
            >
              <option value="">State</option>
              {states.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="zip"
              placeholder="ZIP Code"
              value={returnAddress.zip}
              onChange={(e) => handleInputChange(e, 'return')}
            />
            <button onClick={() => standardizeAddress(returnAddress, setReturnAddress)}>
              Standardize
            </button>
          </div>
        </div>
        <div className="address-section">
          <h3>Recipient Address</h3>
          <input
            type="text"
            name="name"
            placeholder="Full Name/Company Name"
            value={recipientAddress.name}
            onChange={(e) => handleInputChange(e, 'recipient')}
          />
          <input
            type="text"
            name="address1"
            placeholder="Address Line 1"
            value={recipientAddress.address1}
            onChange={(e) => handleInputChange(e, 'recipient')}
          />
          <input
            type="text"
            name="address2"
            placeholder="Address Line 2"
            value={recipientAddress.address2}
            onChange={(e) => handleInputChange(e, 'recipient')}
          />
          <input
            type="text"
            name="city"
            placeholder="City"
            value={recipientAddress.city}
            onChange={(e) => handleInputChange(e, 'recipient')}
          />
          <div className="state-zip-standardize">
            <select
              name="state"
              value={recipientAddress.state}
              onChange={(e) => handleInputChange(e, 'recipient')}
            >
              <option value="">State</option>
              {states.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="zip"
              placeholder="ZIP Code"
              value={recipientAddress.zip}
              onChange={(e) => handleInputChange(e, 'recipient')}
            />
            <button onClick={() => standardizeAddress(recipientAddress, setRecipientAddress)}>
              Standardize
            </button>
          </div>
        </div>
        <button onClick={handleSubmit}>Generate Tracking Number</button>
      </div>
    </div>
  );
};

export default SendMail;
