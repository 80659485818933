// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; // 引入样式文件

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const validateEmail = (email) => {
    // 简单的邮箱验证正则表达式
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // 表单验证
    if (!validateEmail(email)) {
      setError('Please enter a valid email.');
      return;
    }

    if (password.length < 6 || password.length > 16) {
      setError('Password must be between 6 and 16 characters.');
      return;
    }

    // 假设验证通过，跳转到主页（实际应用中应进行后端验证）
    console.log('Email:', email);
    console.log('Password:', password);

    // 假设登录成功
    navigate('/');
  };

  return (
    <div className="login-page">
      <header className="login-header">
        <h1>UUTracking - A Faster way to track Regular USPS First-class Mail</h1>
        <h2>Created by Yifan, powered by USPS Informed Visibility</h2>
      </header>
      <div className="login-container">
        <h2>Login</h2>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit">Login</button>
        </form>
        <div className="login-footer">
          <button onClick={() => navigate('/register')}>Register</button>
          <button onClick={() => navigate('/forgot-password')}>Forgot Password</button>
        </div>
      </div>
    </div>
  );
};

export default Login;
