// src/components/Home.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css'; // 引入样式文件

const Home = () => {
  const navigate = useNavigate();
  const [trackingNumber, setTrackingNumber] = React.useState('');

  const handleTrack = () => {
    // 执行查询逻辑
    console.log('Tracking number:', trackingNumber);
    navigate(`/track?number=${trackingNumber}`);
  };

  return (
    <div className="home-page">
      <header className="home-header">
        <div className="home-header-content">
          <h1>UUTracking</h1>
          <p>A Faster way to track Regular USPS First-class Mail</p>
          <p>Created by Yifan, powered by USPS Informed Visibility</p>
        </div>
        <div className="home-header-buttons">
          <button onClick={() => navigate('/login')}>Login</button>
          <button onClick={() => navigate('/register')}>Register</button>
        </div>
      </header>
      <div className="home-container">
        <div className="home-track">
          <p>Already have a tracking number? Enter it to track!</p>
          <div className="track-input">
            <input
              type="text"
              placeholder="Enter tracking number"
              value={trackingNumber}
              onChange={(e) => setTrackingNumber(e.target.value)}
            />
            <button onClick={handleTrack}>Track</button>
          </div>
        </div>
        <div className="home-send">
          <p>Want to send a letter or postcard? Click the button below!</p>
          <button onClick={() => navigate('/mail')}>Send a Letter/Postcard</button>
        </div>
        <section className="home-features">
          <div className="feature">
            <h2>Generate Tracking Numbers</h2>
            <p>Generate tracking numbers and track Regular USPS First-class mail.</p>
          </div>
          <div className="feature">
            <h2>Supports Letters and Postcards</h2>
            <p>Supports both letters and postcards for your convenience.</p>
          </div>
          <div className="feature">
            <h2>Free Tracking</h2>
            <p>Enjoy free tracking and barcode generation without any charges.</p>
          </div>
          <div className="feature">
            <h2>Powered by USPS Informed Visibility</h2>
            <p>
              Based on USPS Informed Visibility, tracking is limited and not guaranteed. This project is for research purposes only and is not an official USPS service.
            </p>
          </div>
        </section>
      </div>
      <footer className="home-footer">
        <p>Copyright 2024 UUTracking</p>
      </footer>
    </div>
  );
};

export default Home;
