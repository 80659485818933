// src/components/Track.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Track.css'; // 引入样式文件

const Track = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [trackingNumber, setTrackingNumber] = useState('');
  const [displayNumber, setDisplayNumber] = useState('');
  const [trackingInfo, setTrackingInfo] = useState(null);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const number = query.get('number');
    if (number) {
      setTrackingNumber(number);
      setDisplayNumber(number);
      fetchTrackingInfo(number);
    }
  }, [location]);

  const fetchTrackingInfo = (number) => {
    // 假设我们有一个API来获取跟踪信息
    // 这里使用模拟数据
    const mockData = {
      overallStatus: 'Delivered to Agent',
      history: [
        { time: 'July 19, 2024, 10:20 am', status: 'Delivered to Agent', location: 'WOODLAND HILLS, CA 91367' },
        { time: 'July 19, 2024, 6:10 am', status: 'Out for Delivery', location: 'WOODLAND HILLS, CA 91367' },
        { time: 'July 19, 2024, 5:39 am', status: 'Arrived at Post Office', location: 'WOODLAND HILLS, CA 91367' },
        { time: 'July 19, 2024, 4:12 am', status: 'Arrived at USPS Facility', location: 'WOODLAND HILLS, CA 91364' },
        { time: 'July 19, 2024, 3:21 am', status: 'Departed USPS Regional Facility', location: 'SANTA CLARITA CA DISTRIBUTION CENTER' },
        { time: 'July 18, 2024, 8:49 pm', status: 'Arrived at USPS Regional Facility', location: 'SANTA CLARITA CA DISTRIBUTION CENTER' },
        { time: 'July 18, 2024, 5:15 pm', status: 'Accepted at USPS Origin Facility', location: 'SANTA CLARITA, CA 91384' },
      ],
      details: {
        trackingNumber: '9416430109355024583066',
        origin: 'SANTA CLARITA, CA 91384',
        destination: 'WOODLAND HILLS, CA 91367',
        barcodeCreationDate: 'July 18, 2024',
        actualShipmentDate: 'July 18, 2024',
        expectedDeliveryDate: 'July 19, 2024',
        mailType: 'First-Class Mail',
      }
    };

    setTrackingInfo(mockData);
  };

  const handleTrack = () => {
    navigate(`/track?number=${trackingNumber}`);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(displayNumber).then(() => {
      alert('Tracking number copied to clipboard');
    });
  };

  return (
    <div className="track-page">
      <header className="track-header">
        <div className="track-header-content">
          <h1>UUTracking - A Faster way to track Regular USPS First-class Mail</h1>
          <h2>Created by Yifan, powered by USPS Informed Visibility</h2>
        </div>
        <div className="track-header-buttons">
          <button onClick={() => navigate('/login')}>Login</button>
          <button onClick={() => navigate('/register')}>Register</button>
        </div>
      </header>
      <div className="track-container">
        <div className="track-input">
          <input
            type="text"
            placeholder="Enter tracking number"
            value={trackingNumber}
            onChange={(e) => setTrackingNumber(e.target.value)}
          />
          <button onClick={handleTrack}>Track</button>
        </div>
        {displayNumber && (
          <div className="tracking-info">
            <h2>Tracking Number:</h2>
            <div className="tracking-number">
              <span>{displayNumber}</span>
              <button onClick={handleCopy}>Copy</button>
            </div>
          </div>
        )}
        {trackingInfo && (
          <>
            <h2 className="track-overall-status">{trackingInfo.overallStatus}</h2>
            <div className="track-history">
              {trackingInfo.history.map((event, index) => (
                <div key={index} className="track-event">
                  <div className="track-event-time">{event.time}</div>
                  <div className="track-event-status">{event.status}</div>
                  <div className="track-event-location">{event.location}</div>
                </div>
              ))}
            </div>
            <div className="track-details">
              <h3>Mail Details</h3>
              <p><strong>Tracking Number:</strong> {trackingInfo.details.trackingNumber}</p>
              <p><strong>Origin:</strong> {trackingInfo.details.origin}</p>
              <p><strong>Destination:</strong> {trackingInfo.details.destination}</p>
              <p><strong>Barcode Creation Date:</strong> {trackingInfo.details.barcodeCreationDate}</p>
              <p><strong>Actual Shipment Date:</strong> {trackingInfo.details.actualShipmentDate}</p>
              <p><strong>Expected Delivery Date:</strong> {trackingInfo.details.expectedDeliveryDate}</p>
              <p><strong>Mail Type:</strong> {trackingInfo.details.mailType}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Track;
